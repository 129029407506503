<template>
  <div>
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("ENERGY_MANAGEMENT.CONFIG") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <form class="form">
        <div class="card-body mx-4">
          <!-- FILTER AREA -->
          <EnergyFilter
            ref="energyFilter"
            :isGeneration="true"
            :hasLocation="true"
            :hasPeriod="true"
            :isRequiredMonth="true"
            :isRequiredDay="true"
          ></EnergyFilter>
          <!-- PROCESS -->
          <div class="row">
            <button
              @click="processOrganization"
              class="btn btn-primary btn-block"
            >
              {{ $t("ENERGY_MANAGEMENT.PROCESS") }}
            </button>
          </div>
        </div>
      </form>
      <!--end::Card Body-->
    </div>

    <div class="row m-0" v-if="showPredictions">
      <div class="col card card-custom gutter-b p-0">
        <!--begin::Header-->
        <div class="card-header">
          <h3 class="card-title font-weight-bolder text-dark">
            {{ $t("ENERGY_MANAGEMENT.HISTORICAL_DATA") }}
          </h3>
        </div>
        <!--end::Header-->

        <div class="card-body mx-4">
          <div class="text-center" v-if="inProgress">
            <v-progress-circular
              indeterminate
              color="#3699FF"
              size="50"
            ></v-progress-circular>
          </div>
          <div class="row d-flex justify-content-between" v-else>
            <div class="row d-flex justify-content-end my-4">
              <v-dialog v-model="dialog" max-width="500px">
                <!-- ADD NEW BUTTON -->
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    @click="addCorrection"
                    class="btn btn-primary mr-4"
                  >
                    {{ $t("ENERGY_MANAGEMENT.CORRECTION") }}
                  </button>
                </template>

                <!-- ADD EVENT DIALOG -->
                <EventModal
                  :title="$t('EVENT_MANAGEMENT.ADD_NEW')"
                  :details="editedEvent"
                  @close="closeEventModal"
                  @save="saveEventModal"
                  ref="eventModal"
                ></EventModal>
              </v-dialog>
              <button
                v-if="!inProgress && granularity == 'month'"
                class="btn btn-info"
                @click="onExportSynchronizedChartsMonth"
              >
                {{ $t("ENERGY_MANAGEMENT.EXPORT") }}
              </button>
              <button
                class="btn btn-info"
                @click="onExportSynchronizedChartsYear"
                v-if="!inProgress && granularity == 'year'"
              >
                {{ $t("ENERGY_MANAGEMENT.EXPORT") }}
              </button>
              <button
                class="btn btn-info"
                @click="onExportChartsDay"
                v-if="!inProgress && granularity == 'day'"
              >
                {{ $t("ENERGY_MANAGEMENT.EXPORT") }}
              </button>
            </div>
          </div>
          <!-- CHARTS -->

          <!-- <PredictionChartsMonth
            v-if="!inProgress && granularity == 'month'"
            :height="150"
            :events="consumptionPredictionMonth.events"
            :chartData="consumptionPredictionMonth.data"
            :solarRadiation="false"
          /> -->

          <!-- <PredictionChartsYear
            ref="chartYear"
            v-if="!inProgress && granularity == 'year'"
            :height="300"
            :chartData="consumptionPredictionYear"
          /> -->

          <PredictionChartsDay
            v-if="!inProgress && granularity == 'day'"
            :height="300"
            :chartData="consumptionPredictionDay"
            :solar="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnergyService from "@/core/services/energy.service";
import EventsService from "@/core/services/events.service";
// import PredictionChartsMonth from "@/view/content/charts/PredictionChartsMonth.vue";
// import PredictionChartsYear from "@/view/content/charts/PredictionChartsYear.vue";
import PredictionChartsDay from "@/view/content/charts/PredictionChartsDay.vue";
import EventModal from "@/view/content/modals/EventModal.vue";
import EnergyFilter from "@/view/content/filters/EnergyFilter.vue";
import ManagementUtils from "./utils.js";

export default {
  name: "EnergyGeneration",
  components: {
    EnergyFilter,
    // PredictionChartsMonth,
    // PredictionChartsYear,
    PredictionChartsDay,
    EventModal,
  },
  data() {
    return {
      editedEvent: {
        id: 0,
        companyName: "",
        companyCui: "",
        eventType: "",
        value: 0,
        reason: "",
        energyType: "",
        startDate: "",
        startHour: "",
        endDate: "",
        endHour: "",
      },
      dialog: false,
      showPredictions: false,
      inProgress: false,
      consumptionPredictionMonth: {
        data: [],
        events: [],
      },
      consumptionPredictionYear: {},
      consumptionPredictionDay: {
        name: "",
        current: [],
      },
      filter: {
        period: "",
      },
      granularity: "",
    };
  },
  methods: {
    onExportSynchronizedChartsYear() {
      ManagementUtils.onExportSynchronizedChartsYear(
        "",
        this.filter.location.name,
        this.$refs.chartYear.thisYearSeries,
        this.$refs.chartYear.lastYearSeries,
        this.$refs.chartYear.thisYearLabel,
        this.$refs.chartYear.lastYearLabel
      );
    },
    onExportSynchronizedChartsMonth() {
      ManagementUtils.onExportSynchronizedChartsMonth(
        "",
        this.filter.location.name,
        this.consumptionPredictionMonth.data
      );
    },
    onExportChartsDay() {
      ManagementUtils.onExportChartsDay(
        this.filter.location.name,
        "",
        this.consumptionPredictionDay.data
      );
    },
    closeEventModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedEvent = Object.assign({}, this.defaultEvent);
      });
    },
    async saveEventModal() {
      let newEvent = this.$refs.eventModal.details;
      await EventsService.addEvent(newEvent);
      this.closeEventModal();
    },
    processOrganization() {
      this.showPredictions = true;
      this.inProgress = true;
      let energyFilter = this.$refs.energyFilter;

      this.filter = {
        location: energyFilter.location,
        period: energyFilter.period,
      };
      this.granularity = ManagementUtils.getGranularity(energyFilter.period);
      this.getPrediction();
    },
    async getPrediction() {
      // let solarRadiation = await EnergyService.getSolarRadiationChartData(
      //   this.filter.location.id,
      //   this.filter.period
      // );

      let response = await EnergyService.getGenerationChartData(
        this.filter.location.id,
        this.filter.period
      );

      this.inProgress = false;

      if (this.granularity == "year") {
        this.consumptionPredictionYear = response;
      } else if (this.granularity == "month") {
        // response.chartData.solar = solarRadiation.chartData;
        // response.events.solar = solarRadiation.events;
        // this.consumptionPredictionMonth = {
        //   name: this.filter.location.name,
        //   data: response.chartData,
        //   events: response.events,
        // };
      } else if (this.granularity == "day") {
        this.consumptionPredictionDay = {
          name: this.filter.location.name,
          data: response,
        };
      }
    },
    addCorrection() {
      this.editedEvent.companyName = this.filter.location
        ? this.filter.location.name
        : "";
      this.editedEvent.companyCui = this.filter.location
        ? this.filter.location.id
        : "";
      this.editedEvent.energyType = this.$t("FILTER.ENERGY.RENEWABLE");

      this.dialog = true;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.ui.fluid.dropdown {
  display: inherit;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
</style>
